import React, { useState } from "react"
import styled from "styled-components"
import { Container } from "../global"
import appstore from "../../images/appstore.png"
import intention from "../../images/btc-meditation.png"
import { Formik, Form, Field, ErrorMessage } from "formik"
import firebase from "gatsby-plugin-firebase"
import { AuthContext } from "../../context/auth"
import { navigate } from "gatsby"

const SignUp = () => {
  const signUp = async (values) => {
    console.log("values.username.toLowerCase()", values.username.toLowerCase())

    firebase
      .firestore()
      .collection("users")
      .where("username", "==", values.username.toLowerCase())
      .get()
      .then((snapshot) => {
        console.log(snapshot.docs.length) //*** start here */

        if (snapshot.docs.length > 0) {
          alert("Please choose another username.")
          return
        }

        if (snapshot.docs.length === 0) {
          firebase
            .auth()
            .createUserWithEmailAndPassword(values.email, values.password)
            .then((userCredential) => {
              console.log("User account created & signed in!", userCredential)
              addUserToDatabase(userCredential.user, values.username)
            })
            .catch((error) => {
              if (error.code === "auth/email-already-in-use") {
                alert("That email address is already in use.")
              }

              if (error.code === "auth/invalid-email") {
                alert("That email address is invalid.")
              }

              if (error.code === "auth/weak-password") {
                alert("Please use a stronger password.")
              }

              console.error(error)
            })
        }
      })
  }

  const string_parameterize = (str1) => {
    return str1
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/, "")
      .replace(/\s/g, "-")
  }

  const addUserToDatabase = async (user, displayUsername) => {
    var newDate = new Date()
    var pushPermission = "false"
    var token = "web"

    console.log("displayUsername in adduser ", displayUsername)

    firebase
      .firestore()
      .collection("users")
      .add({
        email: user.email.toLowerCase(),
        displayUsername: displayUsername,
        username: string_parameterize(displayUsername),
        perfectDayCount: 0,
        createdAt: newDate.toLocaleDateString(),
        pushPermission: pushPermission,
        showWelcome: true,
        points: 0,
        btcPoints: 0,
        uid: user.uid,
        pointsAllTime: 0,
        redCoins: 0,
        supportersCount: 1,
        currentStreak: 0,
        longestStreak: 0,
        FCMToken: token,
        avatarURL:
          "https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/avatars%2Fdefault-avatar.jpg?alt=media&token=b10df837-6b0c-4ae0-92d0-1253efdb7bb2",
      })
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id)

        firebase
          .firestore()
          .doc("intentions/ExQs7qumjqkrOa4K5CsV")
          .update({
            setters: firebase.firestore.FieldValue.arrayUnion(docRef.id),
          })

        const now = new Date()
        const utc_timestamp = Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds(),
          now.getUTCMilliseconds()
        )

        //Gregory supports all new members, the hardcoded values are from his profile
        firebase.firestore().collection("stars").add({
          giveStar: "ikfK7EuvCy4JtLMM6NPi",
          recieveStar: docRef.id,
          createdAt: utc_timestamp,
          username: "TheSchnaz",
          avatarURL:
            "https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/avatars%2FikfK7EuvCy4JtLMM6NPi_400x400.png?alt=media&token=132b503e-f4d7-4f58-abc3-2a058a51ec4b",
        })

        navigate("/dashboard")
      })
      .catch(function (error) {
        console.error("Error adding document: ", error)
      })
  }

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <center>
              <img src={intention} alt="intention" width="350px" />
            </center>
          </HeaderTextGroup>
          <div>
            <Formik
              initialValues={{ email: "", password: "" }}
              validate={(values) => {
                const errors = {}
                if (!values.email) {
                  errors.email = "Required"
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address"
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                signUp(values)
              }}
            >
              {({ isSubmitting }) => (
                <FormWrapper>
                  <h4>Sign up</h4>
                  <Form>
                    <label htmlFor="username">Username</label>
                    <Field
                      type="username"
                      name="username"
                      style={{ width: "100%" }}
                    />
                    <ErrorMessage name="username" component="div" />
                    <br />
                    <br />
                    <label htmlFor="email">Email Address</label>
                    <Field
                      type="email"
                      name="email"
                      style={{ width: "100%" }}
                    />
                    <ErrorMessage name="email" component="div" />
                    <br />
                    <br />
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      style={{ width: "100%" }}
                    />
                    <ErrorMessage name="password" component="div" />
                    <br />
                    <br />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      style={{
                        width: "300px",
                        height: "35px",
                        backgroundColor: "#FFBB60",
                        color: "#fff",
                        borderRadius: "3px",
                      }}
                    >
                      Sign up
                    </button>
                  </Form>
                </FormWrapper>
              )}
            </Formik>
          </div>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default SignUp

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    margin-top: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`
